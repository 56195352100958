import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { AiFillCloseCircle } from "react-icons/ai";

import * as AssetAPI from "../utils/assetApi";
import classNames from "classnames";

export default function NFTs({
    walletAddress,
    onSelect,
    getTokens,
    onDeselect,
    clear,
    editSelected,
    displayAll,
    selectedAssets,
}) {
    // const [images, setImages] = useState([]);
    const [selected, setSelected] = useState(["", "", "", ""]);
    // const [unselected, setUnselected] = useState(['', '', '', '']);

    const { isLoading, error, data } = useQuery(["nfts", walletAddress], () =>
        AssetAPI.retrieveTokensForWallet(walletAddress, true).then((res) => {
            // console.log(`Retrieved Asset Chunk =>`, res);
            getTokens(res);
            return res;
        })
    );

    if (error) return error;

    useEffect(() => {
        setSelected(["", "", "", ""]);
    }, [clear]);
    useEffect(() => {
        setSelected(editSelected);
    }, [editSelected]);
    const getImageUrl = (tokenId) => {
        return AssetAPI.getImageUrl(tokenId);
    };

    function toggleImageSelected(tokenId) {
        let current = [...selected];
        let index = current.indexOf(tokenId);
        if (index >= 0) {
            // already selected so deselect
            onDeselect(tokenId); // deselect from main DrugWars page
            current[index] = "";
        } else {
            setSelected(["", "", "", ""]);
            let emptyIndex = current.indexOf("");
            if (emptyIndex >= 0) {
                onSelect(tokenId);
                current[emptyIndex] = tokenId;
            }
        }
        setSelected(current);
    }

    // const displayImage = (tokenId) => {
    //     return (
    //         <div key={'selected_' + tokenId} className='w-1/2'>
    //             <div className={classNames(
    //                 'relative overflow-hidden  ',
    //                 {
    //                     "border-2 border-white opacity-70 brightness-75 ":
    //                         selected.includes(tokenId)
    //                 }
    //             )}>
    //                 <img src={getImageUrl(tokenId)} className='rounded-md p-[2%]' />
    //             </div>
    //         </div>
    //     )
    // }

    return (
        <div>
            {isLoading ? (
                <div></div>
            ) : (
                <div>
                    <div
                        className="grid grid-cols-2 sm:grid-cols-3 auto-cols-[calc(36%-0.5rem*2)]
                    md:grid-cols-4 grid-flow-row
                    gap-2 w-full"
                    >
                        {data &&
                            data.map((tokenId) => (
                                <div
                                    key={tokenId}
                                    className={
                                        "cursor-pointer relative rounded-md overflow-hidden"
                                    }
                                    onClick={() => toggleImageSelected(tokenId)}
                                >
                                    <img
                                        src={getImageUrl(tokenId)}
                                        alt={`Drug receipt #${tokenId}`}
                                        className={classNames({
                                            " border-2 border-white opacity-70 brightness-50":
                                                selected.includes(tokenId),
                                        })}
                                    />
                                    <AiFillCloseCircle
                                        className={`absolute top-1 right-1 w-8 h-8 text-white opacity-100
                                        ${selected.includes(tokenId)
                                                ? "block"
                                                : "hidden"
                                            } `}
                                    />
                                </div>
                            ))}
                    </div>
                </div>
            )}
        </div>
    );
}
