

export default function Button({ children, clickFunction, }) {
    return (
        <button onClick={clickFunction}
            className={`inline-block text-[#005c88] font-[600] my-2 px-4 md:px-4 py-1 w-full
                     uppercase
                    rounded-[25px] font-pressio-condensed
                    bg-[#8fbedb]
                    `}
        >
            {children}
        </button>
    )
}