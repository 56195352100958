import React, { useState } from "react";

import MainPage from "../components/MainPage";
import Header from "../components/Header";
import Button from "../components/Button";
import ConnectModal from "../components/ConnectModal";

import logo from "../images/Behind-The-Counter.png";
import rex from "../images/Murdered-Out-Cap.png";
import flightLogo from "../images/drugWar/Hero/Drug-Wars.png";
import drxCharacter from "../images/drugWar/Hero/Drx-Wars-Character.png";
// import cap from "../images/god_of_war_cap.png";

export default function ConnectWallet({ dimensions }) {
  const [connectModalOpen, setConnectModalOpen] = useState(false);

  const closeModal = () => {
    setConnectModalOpen(false);
  };

  const openModal = () => {
    setConnectModalOpen(true);
  };

  return (
    <div className="min-w-screen min-h-screen bg-dwbg bg-cover bg-fixed">
      <div>
        {connectModalOpen && <ConnectModal closeModal={closeModal} />}
        <div className="sticky top-0 z-[30]">
          <Header
            menuItems={[
              "Home",
              "Drug inspector",
              "Drug wars",
              "Drug store"
            ]}
            rightArea={null}
            showSocial={true}
            externalLink={false}
            routes={["https://drugreceipts.com/", "https://druginspector.drugreceipts.com/", "/drugwars", "#"]}
          />
        </div>
        <MainPage>
          <div className="flex flex-col mx-auto pt-[70px] ">
            <div className="flex items-center justify-center w-full gap-8 ">
              <div className="w-full lg:pr-[7%] lg:w-1/2 my-10 mt-[64px]">
                <img className="w-[440px] my-8 " src={logo} alt="logo" />
                <p className="font-Montserrat-Light  text-white text-xl font-light">
                  Connect your wallet to go Behind the Counter and get access to
                  content and perks reserved exclusively for Drug Receipts NFT
                  holders. That’s what I’m snuggin’ bout!
                </p>

                <div className="gap-4 my-10 text-[26px] mt-[40px] lg:w-[80%] md:pr-[10%]">
                  <Button clickFunction={openModal} className="">
                    Connect Wallet
                  </Button>
                  <a href="https://opensea.io/collection/drugreceipts"
                    target="_blank"
                    rel="noreferrer"><Button className="">
                      Become a Holder
                    </Button>
                  </a>
                </div>
                {/* <img className="w-[440px] my-8 " src={logo} alt="logo" />
                <p className="font-pressio-condensed text-white text-6xl font-bold">PREPARE</p>
                <p className="font-pressio-condensed text-white text-6xl font-bold">FOR BATTLE</p>
                <p className="font-open-sans text-white text-xl font-light mt-[20px]">
                  Calling all Drug Snugglers. It’s time to rep your set! Collect
                  and curate Drug Receipts NFTs and pit your best sets against
                  other Drug Snugglers for your chance to win clout and prizes.
                  Do you have what it takes to be the next War Lord?
                </p>

                <div className="gap-4 text-[26px] mt-[40px] md:pr-[10%]">
                  <Button clickFunction={openModal} className="">
                    Connect Wallet
                  </Button>
                  <img src={flightLogo} className="w-[84%] ml-[8%] mt-[20px]"></img>
                </div> */}
              </div>

              {dimensions.width > 900 && (
                <img
                  src={drxCharacter}
                  className="w-1/2 pl-[7%] mb-[64px]"
                  alt="Drug Wars Character"
                  width={470}
                  height={505}
                />
              )}
            </div>
          </div>
        </MainPage>
      </div>
    </div>
  );
}
