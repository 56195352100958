import { useLayoutEffect, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";

import WalletProtected from "./layout/WalletProtected";
import DrugWars from "./pages/DrugWars";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import ConnectWallet from "./pages/ConnectWallet";
import TheLab from "./pages/TheLab";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function getLibrary(provider) {
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

function App() {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    document.title = 'Drug Wars';
  });

  useLayoutEffect(() => {
    function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={<ConnectWallet dimensions={dimensions} />}
            />
            <Route
                path="/drugwars"
                element={
                  <WalletProtected>
                    <DrugWars />
                  </WalletProtected>
                }
              />
            {/* <Route path="/thelab" element={<WalletProtected><TheLab /></WalletProtected>} /> */}
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </Web3ReactProvider>
  );
}

export default App;
