import React, { useRef, useState, useEffect } from "react";
import MainPage from "./MainPage";
import blackFrame from "../images/drugWar/Build-Blank.png";
import cardBack from "../images/drugWar/Card Back/Card Back.png";
const SnugDealers_white = require("../images/drugWar/Builder/Frames/Snug Dealers/SnugDealers_white.png");
const SnugDealers_gray = require("../images/drugWar/Builder/Frames/Snug Dealers/SnugDealers_gray.png");
const SetTrippers_white = require("../images/drugWar/Builder/Frames/Set Trippers/SetTrippers_white.png");
const SetTrippers_gray = require("../images/drugWar/Builder/Frames/Set Trippers/SetTrippers_gray.png");
const SetTrippers_blue = require("../images/drugWar/Builder/Frames/Set Trippers/SetTrippers_blue.png");
const BigPharma_white = require("../images/drugWar/Builder/Frames/Big Pharma/BigPharma_white.png");
const BigPharma_gray = require("../images/drugWar/Builder/Frames/Big Pharma/BigPharma_gray.png");
const BigPharma_blue = require("../images/drugWar/Builder/Frames/Big Pharma/BigPharma_blue.png");
const BigPharma_green = require("../images/drugWar/Builder/Frames/Big Pharma/BigPharma_green.png");
const Kingpin_white = require("../images/drugWar/Builder/Frames/Kingpin/Kingpin_white.png");
const Kingpin_gray = require("../images/drugWar/Builder/Frames/Kingpin/Kingpin_gray.png");
const Kingpin_blue = require("../images/drugWar/Builder/Frames/Kingpin/Kingpin_blue.png");
const Kingpin_green = require("../images/drugWar/Builder/Frames/Kingpin/Kingpin_green.png");
const Kingpin_gold = require("../images/drugWar/Builder/Frames/Kingpin/Kingpin_gold.png");

const Boss_white = require("../images/drugWar/Builder/Frames/Boss/Boss_white.png");
const Boss_gray = require("../images/drugWar/Builder/Frames/Boss/Boss_gray.png");
const Boss_blue = require("../images/drugWar/Builder/Frames/Boss/Boss_blue.png");
const Boss_green = require("../images/drugWar/Builder/Frames/Boss/Boss_green.png");
const Boss_gold = require("../images/drugWar/Builder/Frames/Boss/Boss_gold.png");
const Boss_purple = require("../images/drugWar/Builder/Frames/Boss/Boss_purple.png");

import ReactCardFlip from "react-card-flip";

import flipIcon from "../images/drugWar/Gallery/Gallery/Flip.png";
import flipHoverIcon from "../images/drugWar/Gallery/Gallery/Flip_Hover.png";

import downloadIcon from "../images/drugWar/Gallery/Gallery/Download.png";
import downloadHoverIcon from "../images/drugWar/Gallery/Gallery/Download_Hover.png";


import editIcon from "../images/drugWar/Gallery/Gallery/Edit.png";
import editHoverIcon from "../images/drugWar/Gallery/Gallery/Edit_Hover.png";
import editDisabledIcon from "../images/drugWar/Gallery/Gallery/Edit_Disabled.png";

import submitIcon from "../images/drugWar/Gallery/Gallery/Submit.png";
import submitHoverIcon from "../images/drugWar/Gallery/Gallery/Submit_Hover.png";

import deleteIcon from "../images/drugWar/Gallery/Gallery/Delete.png";
import deleteHoverIcon from "../images/drugWar/Gallery/Gallery/Delete_Hover.png";
import deleteDisabledIcon from "../images/drugWar/Gallery/Gallery/Delete_Disabled.png";

import * as AssetAPI from "../utils/assetApi";
import Button from "../components/Button";
import ButtonDisable from "../components/ButtonDisable";

import * as htmlToImage from "html-to-image";

import { tokenData } from "../utils/data";
import { tokeIdToRank } from "../utils/rank";

import SubmitModal from "../components/SubmitModal";

import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

export default function Gallery({
  walletAddress,
  galleries,
  refreshGallery,
  executeScrollToBuilder,
  editGallery,
  downloadGallery,
  clearAllWithInfo,
}) {
  const domEl = useRef(null);
  const [flip, setFlip] = useState([]);
  const [emailHandle, setEmailHandle] = useState("");
  const [discordHandle, setDiscordHandle] = useState("");
  const [twitterHandle, setTwitterHandle] = useState("");
  const [existContact, setExistContact] = useState(false);
  const [errorfield, setErrorfield] = useState("");
  const [submitId, setSubmitId] = useState(-1);
  const [submitGalleryTeamname, setSubmitGalleryTeamname] = useState("");

  const [submitModalOpen, setSubmitModalOpen] = useState(false);

  const closeModal = () => {
    setSubmitModalOpen(false);
  };

  const openModal = () => {
    setSubmitModalOpen(true);
  };

  const downloadImage = async (id) => {
    // console.log("OOOOOOOOO");
    const dataUrl = await htmlToImage.toPng(
      document.getElementById("domEl" + id)
    );
    // console.log("#########", dataUrl);
    // download image
    const link = document.createElement("a");
    link.download = "drugreceipts.png";
    link.href = dataUrl;
    link.click();
  };
  useEffect(async () => {
    if (walletAddress != "") {
      let res = await AssetAPI.getContactByAddress(walletAddress);
      let contact_data = await res.json();
      if (res.ok) {
        // console.log("$$$$$$$$$$$$$$$$$$$$$$", gallery);
        if (contact_data.length > 0) {
          setEmailHandle(contact_data[0]["email"]);
          setDiscordHandle(contact_data[0]["discord"]);
          setTwitterHandle(contact_data[0]["twitter"]);
          setExistContact(true);
        }
      }
    }
  }, [walletAddress]);

  const saveContact = async () => {
    if (walletAddress != "") {
      if (emailHandle != "" && discordHandle != "" && twitterHandle != "") {
        setErrorfield("");
        let data = {
          walletAddress: walletAddress,
          // walletAddress: "0x7f8Aa85898eeAcEF75c98799e6a308782bbeC7B9",
          email: emailHandle,
          discord: discordHandle,
          twitter: twitterHandle,
        };
        let res = await AssetAPI.saveContact(data);
        if (res.ok) {
          if (existContact) {
            NotificationManager.success("Successfully updated");
          } else {
            NotificationManager.success("Successfully saved");
          }
          setExistContact(true);
          // console.log("$$$$$$$$$$$$$$$$$$$$$$", gallery);
          // if(contact_data.length>0){
          //   setEmailHandle(contact_data[0]['email']);
          //   setDiscordHandle(contact_data[0]['discord']);
          //   setTwitterHandle(contact_data[0]['twitter']);
          // }
        }
      } else {
        setErrorfield("You have to input all 3 fields.");
        // alert("You have to input all 3 fields.")
      }
    }
  };

  const getImage = (frameUrl) => {
    if (frameUrl == "SnugDealers_white") return SnugDealers_white;
    else if (frameUrl == "SnugDealers_gray") return SnugDealers_gray;
    else if (frameUrl == "SetTrippers_white") return SetTrippers_white;
    else if (frameUrl == "SetTrippers_gray") return SetTrippers_gray;
    else if (frameUrl == "SetTrippers_blue") return SetTrippers_blue;
    else if (frameUrl == "BigPharma_white") return BigPharma_white;
    else if (frameUrl == "BigPharma_gray") return BigPharma_gray;
    else if (frameUrl == "BigPharma_blue") return BigPharma_blue;
    else if (frameUrl == "BigPharma_green") return BigPharma_green;
    else if (frameUrl == "Kingpin_white") return Kingpin_white;
    else if (frameUrl == "Kingpin_gray") return Kingpin_gray;
    else if (frameUrl == "Kingpin_blue") return Kingpin_blue;
    else if (frameUrl == "Kingpin_green") return Kingpin_green;
    else if (frameUrl == "Kingpin_gold") return Kingpin_gold;
    else if (frameUrl == "Boss_white") return Boss_white;
    else if (frameUrl == "Boss_gray") return Boss_gray;
    else if (frameUrl == "Boss_blue") return Boss_blue;
    else if (frameUrl == "Boss_green") return Boss_green;
    else if (frameUrl == "Boss_gold") return Boss_gold;
    else if (frameUrl == "Boss_purple") return Boss_purple;
    else return SnugDealers_white;
  };
  const deleteGallery = async (id) => {
    let res = await AssetAPI.deleteGallery(id);
    if (res.ok) {
      res = await res.json();
      NotificationManager.success("Outta here! Team successfully deleted.");
      refreshGallery();
    } else {
      res = await res.json();
      NotificationManager.error(res.message);
    }
  };
  const submitGallery = async (id, tempteamName) => {
    setSubmitId(id);
    setSubmitGalleryTeamname(tempteamName);
    openModal();
  };
  const submitModal = async () => {
    if (walletAddress != "") {
      if (emailHandle != "" && discordHandle != "" && twitterHandle != "") {
        setErrorfield("");
        let data = {
          walletAddress: walletAddress,
          // walletAddress: "0x7f8Aa85898eeAcEF75c98799e6a308782bbeC7B9",
          email: emailHandle,
          discord: discordHandle,
          twitter: twitterHandle,
        };
        let res = await AssetAPI.saveContact(data);
        if (res.ok) {
          let res1 = await AssetAPI.submitGallery(submitId);
          if (res1.ok) {
            res1 = await res1.json();
            NotificationManager.success("Successfully submitted");
            refreshGallery();
            clearAllWithInfo();
            setSubmitId(-1);
            closeModal();
          } else {
            res1 = await res1.json();
            NotificationManager.error(res1.message);
          }
          setExistContact(true);
        }
      } else {
        setErrorfield("You have to input all 3 fields.");
      }
    }
  }

  const getImageUrl = (tokenId) => {
    return AssetAPI.getImageUrl(tokenId);
  };

  const flipCard = (id) => {
    let temp = [...flip];
    if (temp[id] == false || temp[id] == undefined) {
      temp[id] = true;
    } else {
      temp[id] = false;
    }
    setFlip(temp);
  };

  const getTeamMemberColor = (frameUrl) => {
    let color = "#939598";
    if (frameUrl.slice(-5) == "white") {
      color = "#939598";
    } else if (frameUrl.slice(-5) == "green") {
      color = "#2D3F1C";
    } else if (frameUrl.slice(-4) == "gray") {
      color = "#323133";
    } else if (frameUrl.slice(-4) == "blue") {
      color = "#072947";
    } else if (frameUrl.slice(-4) == "gold") {
      color = "#4B3515";
    } else if (frameUrl.slice(-6) == 'purple') {
      color = "#4B3515";
    } else {
      color = "#939598";
    }
    // color = "text-[" + color + "]";
    return color;
  };
  return (
    <div>
      {submitModalOpen && <SubmitModal submitGalleryTeamname={submitGalleryTeamname} submitModal={submitModal} emailHandle={emailHandle} discordHandle={discordHandle} twitterHandle={twitterHandle} setEmailHandle={setEmailHandle} setDiscordHandle={setDiscordHandle} setTwitterHandle={setTwitterHandle} closeModal={closeModal} />}
      <div className="lg:max-w-[1140px] mx-auto px-5 lg:px-0 min-h-[100vh] pb-[100px]">
        <div className="pb-[60px]">
          {/* <div className="col-span-1"> */}
          <div className="text-white pr-[32px] pt-[128px]">
            <h1 className="font-pressio-condensed text-5xl lg:text-6xl font-medium mb-2">
              BATTLECARD GALLERY
            </h1>
            <p className="font-Montserrat-Light text-lg lg:text-xl font-light mt-4">
              Use the gallery to review, share, and edit your teams. When you
              have a team that is worthy of battle you may submit it from here.
              You do not have to submit every team you create. Only submit your
              best candidates. There is no limit to the amount of battlecards you may submit but
              the most that can be accepted into the Tournament from any single
              player is four. Once you've submitted an entry you will no longer be able to make
              edits. Submit wisely! Create. Curate. Conquer.
            </p>
          </div>
          {/* </div> */}
          {/* <div className="col-span-1">
            <div className="text-white py-10 pt-[128px]">
              <h1 className="font-pressio-condensed text-5xl lg:text-6xl font-medium mb-2">
                CONTACT DETAILS
              </h1>
              <p className="font-open-sans text-lg lg:text-xl max-w-3xl font-light mt-4">
                Please provide your basic detail in order for the Drug Receipts
                team to get in touch with you during Drug Wars.
              </p>
              <div className="mt-3">
                <input
                  class="rounded-[8px] p-3 pl-5 w-full rounded-[16px] mt-2 text-black"
                  placeholder='Email "greg@drugreceipts.com"'
                  value={emailHandle}
                  onChange={({ target: { value } }) => {
                    // if (value.length <= 24) {
                    setEmailHandle(value);
                    // }
                  }}
                ></input>
              </div>
              <div className="grid grid-cols-2 gap-4 mt-2">
                <input
                  class="rounded-[8px] p-3 pl-5 w-full rounded-[16px] mt-2 text-black"
                  placeholder="Discord I.D"
                  value={discordHandle}
                  onChange={({ target: { value } }) => {
                    // if (value.length <= 24) {
                    setDiscordHandle(value);
                    // }
                  }}
                ></input>
                <input
                  class="rounded-[8px] p-3 pl-5 w-full rounded-[16px] mt-2 text-black"
                  placeholder="Twitter @"
                  value={twitterHandle}
                  onChange={({ target: { value } }) => {
                    // if (value.length <= 24) {
                    setTwitterHandle(value);
                    // }
                  }}
                ></input>
              </div>
              <p className="text-red-400 mt-1 ml-1 h-[4px]">{errorfield}</p>
              <div className="w-[50%] ml-[50%] pl-3 mt-2">
                <Button
                  clickFunction={() => {
                    saveContact();
                  }}
                >
                  <a
                    className="text-[24px] font-pressio-condensed"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {existContact ? "UPDATE DETAILS" : "SAVE DETAILS"}
                  </a>
                </Button>
              </div>
            </div>
          </div> */}
        </div>
        <div>
          <div
            className="grid grid-cols-1 sm:grid-cols-2 auto-cols-[calc(36%-0.5rem*2)]
                    md:grid-cols-3 grid-flow-row
                    gap-2 gap-y-12 w-full"
          >
            {galleries &&
              galleries
                .slice(0)
                .reverse()
                .map((gallery) => (
                  <div className="flex flex-col items-center" key={gallery.id}>
                    <ReactCardFlip
                      isFlipped={flip[gallery.id]}
                      flipDirection="horizontal"
                    >
                      <div
                        id={`domEl${gallery.id}`}
                        ref={domEl}
                        className={`w-[340px] h-[408.9px] relative`}
                      >
                        {window.innerWidth < 900 && (
                          <div>
                            <div className="w-[146px] h-[146px] ml-[21px] mt-[21px] absolute">
                              <object
                                data={getImageUrl(gallery.token1)}
                                type="image/svg+xml"
                                style={{
                                  transform: "scale(0.34) translate(-98%, -98%)",
                                  width: "432px",
                                  height: "432px",
                                }}
                                alt={`Drug receipt #${gallery.token1}`}
                                width="100%"
                                height="100%"
                              ></object>
                            </div>
                            <div className="w-[146px] h-[146px] ml-[172px] mt-[21px] absolute">
                              <object
                                data={getImageUrl(gallery.token2)}
                                type="image/svg+xml"
                                style={{
                                  transform: "scale(0.34) translate(-98%, -98%)",
                                  width: "432px",
                                  height: "432px",
                                }}
                                alt={`Drug receipt #${gallery.token2}`}
                                width="100%"
                                height="100%"
                              ></object>
                            </div>
                            <div className="w-[146px] h-[146px] ml-[21px] mt-[172px] absolute">
                              <object
                                data={getImageUrl(gallery.token3)}
                                type="image/svg+xml"
                                style={{
                                  transform: "scale(0.34) translate(-98%, -98%)",
                                  width: "432px",
                                  height: "432px",
                                }}
                                alt={`Drug receipt #${gallery.token3}`}
                                width="100%"
                                height="100%"
                              ></object>
                            </div>
                            <div className="w-[146px] h-[146px] ml-[172px] mt-[172px] absolute">
                              <object
                                data={getImageUrl(gallery.token4)}
                                type="image/svg+xml"
                                style={{
                                  transform: "scale(0.34) translate(-98%, -98%)",
                                  width: "432px",
                                  height: "432px",
                                }}
                                alt={`Drug receipt #${gallery.token4}`}
                                width="100%"
                                height="100%"
                              ></object>
                            </div>
                          </div>)}
                        {window.innerWidth >= 900 && (
                          <div>
                            <img src={getImageUrl(gallery.token1)} alt={`Drug receipt #${gallery.token1}`} className="w-[146px] h-[146px] ml-[21px] mt-[21px] absolute"></img>
                            <img src={getImageUrl(gallery.token2)} alt={`Drug receipt #${gallery.token2}`} className="w-[146px] h-[146px] ml-[172px] mt-[21px] absolute"></img>
                            <img src={getImageUrl(gallery.token3)} alt={`Drug receipt #${gallery.token3}`} className="w-[146px] h-[146px] ml-[21px] mt-[172px] absolute"></img>
                            <img src={getImageUrl(gallery.token4)} alt={`Drug receipt #${gallery.token4}`} className="w-[146px] h-[146px] ml-[172px] mt-[172px] absolute"></img>
                          </div>
                        )}
                        {/* <img src={getImageUrl(gallery.token1)} alt={`Drug receipt #${gallery.token1}`} className="w-[145px] h-[145px] ml-[22.5px] mt-[22px] absolute"></img>
                            <img src={getImageUrl(gallery.token2)} alt={`Drug receipt #${gallery.token2}`} className="w-[145px] h-[145px] ml-[173px] mt-[22px] absolute"></img>
                            <img src={getImageUrl(gallery.token3)} alt={`Drug receipt #${gallery.token3}`} className="w-[145px] h-[145px] ml-[22.5px] mt-[173px] absolute"></img>
                            <img src={getImageUrl(gallery.token4)} alt={`Drug receipt #${gallery.token4}`} className="w-[145px] h-[145px] ml-[173px] mt-[173px] absolute"></img> */}
                        <img
                          src={getImage(gallery.frame)}
                          className="max-w-[340px] absolute"
                        ></img>
                        <div className="absolute w-full top-[354px]">
                          <p
                            className={`text-[20px] text-center h-[30px] ${gallery.frame.slice(-5) == "white"
                              ? "text-[#414042]"
                              : "text-white"
                              }`}
                            style={{ fontFamily: "octin-stencil" }}
                          >
                            {gallery.teamName}
                          </p>
                          <p
                            className={`text-[12px] text-center font-normal font-pressio-condensed -mt-2 ${getTeamMemberColor(
                              gallery.frame
                            )}`}
                            style={{ color: getTeamMemberColor(gallery.frame) }}
                          >
                            {gallery.teamMember}
                          </p>
                        </div>
                      </div>
                      <div
                        id={`domEl${gallery.id}`}
                        ref={domEl}
                        className={`w-[340px] h-[408.9px] relative`}
                      >
                        <img
                          src={cardBack}
                          className="max-w-[340px] absolute"
                        ></img>
                        <div className="absolute w-full top-[22px] px-[30px]">
                          <p
                            className={`text-[20px] text-center text-[#818386] h-[30px]`}
                            style={{ fontFamily: "octin-stencil" }}
                          >
                            {gallery.teamName}
                          </p>
                          <p
                            className={`text-[9.2px] text-left font-normal mt-[2px] text-[#818386]`}
                            style={{
                              lineHeight: "0.8rem",
                              fontFamily: "Montserrat-Regular",
                              whiteSpace: "pre-wrap"
                            }}
                          >
                            {`${gallery.teamLore}`}
                          </p>
                        </div>
                        <div className=" absolute w-full px-[30px] top-[262px]">
                          <table class="table-auto text-center text-[#818386] text-[9.4px] w-full">
                            <thead>
                              <tr
                                className="text-[9px] border-[#818386] border-t font-OpenSans_Condensed-Bold"
                              >
                                <th>DRX ID</th>
                                <th>RANK</th>
                                <th>CHARACTER</th>
                                <th>BODY COLOR</th>
                                <th>BG COLOR</th>
                              </tr>
                            </thead>
                            {/* canvasTxt.drawText(context, nfts[i].toString(), 120, 1122+100*l, 200, 100);
                              canvasTxt.drawText(context, tokeIdToRank[nfts[i]].toString(), 320, 1122+100*l, 200, 100);
                              canvasTxt.drawText(context, tokenData[nfts[i]].Character.toString(), 520, 1122+100*l, 300, 100);
                              canvasTxt.drawText(context, tokenData[nfts[i]]["Body Color"].toString(), 820, 1122+100*l, 300, 100);
                              canvasTxt.drawText(context, tokenData[nfts[i]].Background.toString(), 1120, 1122+100*l, 300, 100); */}
                            <tbody
                              style={{ fontFamily: "OpenSans_Condensed-Light" }}
                            >
                              <tr className="border-[#818386] border-t">
                                <td>{gallery.token1.toString()}</td>
                                <td>{tokeIdToRank[gallery.token1].toString()}</td>
                                <td>
                                  {tokenData[gallery.token1].Character.toString()}
                                </td>
                                <td>
                                  {tokenData[gallery.token1][
                                    "Body Color"
                                  ].toString()}
                                </td>
                                <td>
                                  {tokenData[
                                    gallery.token1
                                  ].Background.toString()}
                                </td>
                              </tr>
                              <tr className="border-[#818386] border-t">
                                <td>{gallery.token2.toString()}</td>
                                <td>{tokeIdToRank[gallery.token2].toString()}</td>
                                <td>
                                  {tokenData[gallery.token2].Character.toString()}
                                </td>
                                <td>
                                  {tokenData[gallery.token2][
                                    "Body Color"
                                  ].toString()}
                                </td>
                                <td>
                                  {tokenData[
                                    gallery.token2
                                  ].Background.toString()}
                                </td>
                              </tr>
                              <tr className="border-[#818386] border-t">
                                <td>{gallery.token3.toString()}</td>
                                <td>{tokeIdToRank[gallery.token3].toString()}</td>
                                <td>
                                  {tokenData[gallery.token3].Character.toString()}
                                </td>
                                <td>
                                  {tokenData[gallery.token3][
                                    "Body Color"
                                  ].toString()}
                                </td>
                                <td>
                                  {tokenData[
                                    gallery.token3
                                  ].Background.toString()}
                                </td>
                              </tr>
                              <tr className="border-[#818386] border-t">
                                <td>{gallery.token4.toString()}</td>
                                <td>{tokeIdToRank[gallery.token4].toString()}</td>
                                <td>
                                  {tokenData[gallery.token4].Character.toString()}
                                </td>
                                <td>
                                  {tokenData[gallery.token4][
                                    "Body Color"
                                  ].toString()}
                                </td>
                                <td>
                                  {tokenData[
                                    gallery.token4
                                  ].Background.toString()}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </ReactCardFlip>
                    <div className="w-[340px] mt-4">
                      <div className={gallery.submited > 0 ? "hidden" : "block"}>
                        <Button
                          clickFunction={() => {
                            submitGallery(gallery.id, gallery.teamName);
                          }}
                        >
                          <a
                            className="text-[24px] font-pressio-condensed"
                            target="_blank"
                            rel="noreferrer"
                          >
                            SUBMIT ENTRY
                          </a>
                        </Button>
                      </div>
                      <div className={gallery.submited > 0 ? "block" : "hidden"}>
                        <ButtonDisable>
                          <a
                            className="text-[24px] font-pressio-condensed"
                            target="_blank"
                            rel="noreferrer"
                          >
                            SUBMITTED
                          </a>
                        </ButtonDisable>
                      </div>
                      <div className="grid grid-cols-4 mt-3 px-3">
                        <div className="col-span-1">
                          <img
                            src={flipIcon}
                            onClick={() => flipCard(gallery.id)}
                            onMouseOver={(e) =>
                              (e.currentTarget.src = flipHoverIcon)
                            }
                            onMouseOut={(e) => (e.currentTarget.src = flipIcon)}
                            className="w-[70%] ml-[15%] cursor-pointer"
                          ></img>
                        </div>
                        <div className="col-span-1">
                          <img
                            src={downloadIcon}
                            onClick={() => {
                              downloadGallery(gallery);
                            }}
                            onMouseOver={(e) =>
                              (e.currentTarget.src = downloadHoverIcon)
                            }
                            onMouseOut={(e) =>
                              (e.currentTarget.src = downloadIcon)
                            }
                            className="w-[70%] ml-[15%] cursor-pointer"
                          ></img>
                        </div>
                        {/* <div className='col-span-1'>
                      <img src={submitIcon} onMouseOver={e => (e.currentTarget.src = submitHoverIcon)} onMouseOut={e => (e.currentTarget.src = submitIcon)} className="w-[70%] ml-[15%] cursor-pointer"></img>
                    </div> */}
                        <div className="col-span-1">
                          <img
                            src={gallery.submited == 0 ? editIcon : editDisabledIcon}
                            onClick={() => {
                              if (gallery.submited == 0) {
                                editGallery(gallery);
                                executeScrollToBuilder();
                              }
                            }}
                            onMouseOver={(e) =>
                              gallery.submited == 0
                                ? (e.currentTarget.src = editHoverIcon)
                                : ""
                            }
                            onMouseOut={(e) => (e.currentTarget.src = gallery.submited == 0 ? editIcon : editDisabledIcon)}
                            className={`w-[70%] ml-[15%] cursor-pointer ${gallery.submited == 0 ? "" : ""
                              }`}
                          ></img>
                        </div>
                        <div className="col-span-1">
                          <img
                            src={gallery.submited == 0 ? deleteIcon : deleteDisabledIcon}
                            onClick={() => {
                              if (gallery.submited == 0)
                                deleteGallery(gallery.id);
                            }}
                            onMouseOver={(e) =>
                              gallery.submited == 0
                                ? (e.currentTarget.src = deleteHoverIcon)
                                : ""
                            }
                            onMouseOut={(e) => (e.currentTarget.src = gallery.submited == 0 ? deleteIcon : deleteDisabledIcon)}
                            className={`w-[70%] ml-[15%] cursor-pointer ${gallery.submited == 0 ? "" : ""
                              }`}
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

            <div className="flex flex-col items-center">
              <img src={blackFrame} className="max-w-[340px]"></img>
              <div className="w-[340px] mt-4">
                <Button
                  clickFunction={() => {
                    executeScrollToBuilder();
                    clearAllWithInfo();
                  }}
                >
                  <a
                    className="text-[24px] font-pressio-condensed"
                    target="_blank"
                    rel="noreferrer"
                  >
                    CREATE A TEAM
                  </a>
                </Button>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
    </div>
  );
}
