import { ReactComponent as Close } from "../images/X.svg";
import React, { useState, useRef, useEffect } from "react";
// import cap from "../images/Murdered-Out-Cap.png";

import WalletProtected from "../layout/WalletProtected";
import Wallet from "../pages/Wallet";
import drxCharacter from "../images/drugWar/Hero/Drx-Wars-Character.png";
import Button from "../components/Button";

import { Scrollbars } from "react-custom-scrollbars";

const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
        borderRadius: 6,
        backgroundColor: "#0c5977",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = (props) => (
    <Scrollbars
        renderThumbHorizontal={renderThumb}
        renderThumbVertical={renderThumb}
        {...props}
    />
);

export default function SubmitModal({ submitGalleryTeamname, submitModal, closeModal, emailHandle, discordHandle, twitterHandle, setEmailHandle, setDiscordHandle, setTwitterHandle}) {
    // const [emailHandle, setEmailHandle] = useState("");
    // const [discordHandle, setDiscordHandle] = useState("");
    // const [twitterHandle, setTwitterHandle] = useState("");

    return (
        <div
            className="fixed flex flex-wrap justify-center items-center 
        top-0 left-0 bottom-0 -right-0 z-10 
        bg-opacity-60 bg-black backdrop-blur-sm w-screen h-full"
        >
            {/* <CustomScrollbars> */}
            <div
                className="flex flex-col fixed p-5 pr-3
            w-[80%] md:w-[50%] lg:w-[80%] xl:w-[60%] max-w-[1160px] 3xl:h-[60%] bg-dwbg bg-cover rounded-3xl overflow-y-auto h-[80vh] lg:h-[730px]"
            >
                <CustomScrollbars>
                    <div className="max-h-[80vh] max-h-[720px] pr-2">
                        <div
                            onClick={closeModal}
                            className="flex fixed sm:absolute right-0 top-0 p-3 m-2 
                    justify-center items-center h-14 w-14 rounded-full
                    hover:cursor-pointer transform hover:border-4 "
                        >
                            <Close className="" />
                        </div>

                        <div className="text-white justify-center text-left items-center w-[100%] px-[12px] md:pl-[36px] md:pt-[16px] md:pr-[36px]">

                            <div>
                                <h1 className="font-pressio-condensed text-4xl sm:text-6xl mt-4" style={{ fontWeight: '500' }}>
                                    OFFICIAL DRUG WARS SUBMISSION
                                </h1>
                                <div className="text-white tracking-wide font-Montserrat-Light">
                                    <p className="mt-8 text-[20px] font-light">Please confirm that you would like to submit the following team:</p>
                                    <div>
                                        <p className="font-octin-stencil text-[28px] md:text-[36px] mt-1">{submitGalleryTeamname}</p>
                                        <p className="mt-4 text-[20px] font-light">Your submission will be considered for entry into the Drug Wars Tournament.</p>
                                        <p className="mt-5 text-[20px] font-light">Submitting your team does not guarantee entry into the Tournament. All teams will be selected at the sole discretion of the Drug Overlords. See the <a href="https://drugreceipts.com/drug-wars-tc/" className="font-bold underline cursor-pointer font-Montserrat-Regular" target="_blank">Terms and Conditions</a> for more details.</p>
                                        <p className="mt-5 text-[20px] font-light">Note that once you submit your team you will not be able to make any changes.</p>
                                        <p className="mt-5 text-[20px] font-light">Contact info is required for all teams accepted into the Tournament.</p>
                                        <p className="text-[20px] mt-1 font-Montserrat-Regular">Please provide the required info below:</p>
                                        
                                    </div>
                                    <div className="mt-4 text-[20px] font-semibold">
                                        <div className="block md:flex flex-row gap-4 space-y-4 md:space-y-0">
                                            <div className="md:w-[40%]">
                                                <p>Email</p>
                                                <input
                                                    class="rounded-[8px] p-2 pl-4 w-full rounded-[8px] mt-2 text-lg text-black font-open-sans font-light"
                                                    style={{fontFamily:emailHandle.length>0?'Montserrat-Light':'Open Sans'}}
                                                    placeholder='greg@drugreceipts.com'
                                                    value={emailHandle}
                                                    onChange={({ target: { value } }) => {
                                                        // if (value.length <= 24) {
                                                        setEmailHandle(value);
                                                        // }
                                                    }}
                                                ></input>
                                            </div>
                                            <div className="md:w-[20%]">
                                                <p>Discord ID</p>
                                                <input
                                                    class="rounded-[8px] p-2 pl-4 w-full rounded-[8px] mt-2 text-lg text-black font-open-sans font-light"
                                                    placeholder="Greg#6969"
                                                    style={{fontFamily:discordHandle.length>0?'Montserrat-Light':'Open Sans'}}
                                                    value={discordHandle}
                                                    onChange={({ target: { value } }) => {
                                                        // if (value.length <= 24) {
                                                        setDiscordHandle(value);
                                                        // }
                                                    }}
                                                ></input>
                                            </div>
                                            <div className="md:w-[20%]">
                                                <p>Twitter @</p>
                                                <input
                                                    class="rounded-[8px] p-2 pl-4 w-full rounded-[8px] mt-2 text-lg text-black font-open-sans font-light"
                                                    placeholder="@gregreceipts"
                                                    style={{fontFamily:twitterHandle.length>0?'Montserrat-Light':'Open Sans'}}
                                                    value={twitterHandle}
                                                    onChange={({ target: { value } }) => {
                                                        // if (value.length <= 24) {
                                                        setTwitterHandle(value);
                                                        // }
                                                    }}
                                                ></input>
                                            </div>
                                        </div>
                                        <div className="block md:flex flex-row gap-6 mt-6 mb-4 pb-[20px]">
                                            <div className="md:w-[220px]">
                                                <Button
                                                    clickFunction={() => {
                                                        submitModal();
                                                    }}
                                                >
                                                    <a
                                                        className="text-[24px] font-pressio-condensed"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        SUBMIT
                                                    </a>
                                                </Button>
                                            </div>
                                            <div className="md:w-[220px]">
                                                <Button
                                                    clickFunction={() => {
                                                        closeModal();
                                                    }}
                                                >
                                                    <a
                                                        className="text-[24px] font-pressio-condensed"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        CANCEL
                                                    </a>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </CustomScrollbars>
            </div>
            {/* </CustomScrollbars> */}
        </div>
    );
}
