import React from "react";


export default function MainPage({ children }) {

    return (
        <div className="lg:max-w-[1140px] mx-auto px-5 lg:px-0 min-h-[100vh] -mt-[80px] flex items-center">
            {children}
        </div>
    );
}
