const Loader = () => {
    return (
        <div>
            <div class="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center bg-white opacity-[30%]">
            </div>
            <div class="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center">
                <div class="bg-white border py-2 px-5 rounded-lg flex items-center flex-col">
                    <div class="w-12 h-12 border-4 border-blue-600 rounded-full loader"></div>
                    <div class="text-gray-500 text-xs font-medium mt-2 text-center">
                        Loading...
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loader;