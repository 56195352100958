import React, { useState, useRef, useEffect } from "react";
import Header from "../components/Header";
import MainPage from "../components/MainPage";
import NFTs from "../components/NFTs";
import FrameCanvas from "../components/FrameCanvas";
import FrameBackCanvas from "../components/FrameBackCanvas";

import flightLogo from "../images/drugWar/Hero/Drug-Wars.png";
import drxCharacter from "../images/drugWar/Hero/Drx-Wars-Character.png";

import Button from "../components/Button";

import Gallery from "../components/Gallery";

import { Scrollbars } from "react-custom-scrollbars";
import { useWeb3React } from "@web3-react/core";

import JSZip from "jszip";
import FileSaver from "file-saver";
import JSZipUtils from "jszip-utils";

import { tokenData } from "../utils/data";
import stampImg from "../images/drugWar/DrugWars-Stamp.png";

import ButtonWar from "../components/ButtonWar";
import Loader from "../utils/loader";

import ReactCardFlip from "react-card-flip";

import * as AssetAPI from "../utils/assetApi";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "#a97fb1",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

const whitelist = [
  "0x6AAEC7234F5Af0F693636e104aE7f4866d2b8D83",
  "0xC1626B779d226106484e3D3A148DD3533D3Ae9e3",
  "0x2D28B29786c9166304574Df5a8bcEC28398a3bF1", 
  "0x2d28b29786c9166304574df5a8bcec28398a3bf1",
  "0x0faB9a6914cd773804412Bdbc0415356Ac38E90D", 
  "0xEf7D6E6796278c1bC1Af2f721A75CB634f09a34b",
  "0xef7d6e6796278c1bc1af2f721a75cb634f09a34b", 
  "0x01207ACf8705847E112eCBF185A50d4779Ad7188", 
  "0xc06688c0DA2f736Cd787d4Fd1bDd473B046B9215",
  "0x0CD72FA15D5aFED8Bc115AF164428d4dcE88B67e",
  "0x7f8Aa85898eeAcEF75c98799e6a308782bbeC7B9",
  "0xE9aA73E8E4aaABEb070b66F612510be26598Ab1B",
  "0xef5c532bc5Fa6a2821159863902893be6A09E038",
  "0xE2a0Ed7516De6013dB37633d2F135ECBa5208bFa",
  "0x2B5280D2BEFCfe1AaD2028d71D3cf7A4EF3f8C5f"
];

let links = [];

export default function DrugWars() {
  const wallet = useWeb3React();
  const [isloading, setIsloading] = useState(false);
  const [selectedImages, setSelectedImages] = useState(["", "", "", ""]);
  const [teamName, setTeamName] = useState("");
  const [teamLore, setTeamLore] = useState("");
  const [teamLoreLength, setTeamLoreLength] = useState(0);

  const [teamMember, setTeamMember] = useState('');
  const [type, setType] = useState(0);
  const [frameImage, setFrameImage] = useState("");
  const [tokens, setTokens] = useState([]);
  const [clear, setClear] = useState(false);
  const [downloaded, setDownloaded] = useState(0);
  const [editSelected, setEditSelected] = useState(["", "", "", ""]);

  const [editGalleryNumber, setEditGalleryNumber] = useState(-1);

  const [galleries, setGalleries] = useState([]);

  const [gallery, setGallery] = useState(false);
  const [flip, setFlip] = useState(false);

  const [whitelistWalletConnected, setWhitelistWalletConnected] = useState(false);

  const galleryRef = useRef(null);
  const builderRef = useRef(null);

  useEffect(() => {
    // console.log('galleries are changed', galleries);
  }, [galleries])

  useEffect(() => {
    let flag = false;
    // console.log(wallet.account);
    for (let i = 0; i < whitelist.length; i++) {
      if (whitelist[i].toLowerCase() == wallet.account.toLowerCase()) {
        flag = true;
      }
    }
    if (flag) {
      setType(5);
    }
    setWhitelistWalletConnected(flag);
  }, [wallet.activate]);

  useEffect(() => {
    // console.log('refreshed')
    refreshGallery();
  }, []);

  const clearAll = () => {
    if (!flip) {
      // setTeamName("");
      setSelectedImages(["", "", "", ""]);
      // setTeamLore("");
      // setFrameImage("");
      setTeamMember("");
      setClear(!clear);
    }
  };

  const clearAllWithInfo = () => {
    setTeamName("");
    setTeamMember("");
    setFrameImage("");
    setSelectedImages(["", "", "", ""]);
    setTeamLore("");
    setFlip(false);
    setClear(!clear);
    setEditGalleryNumber(-1);
  };

  const executeScrollToGallery = () => galleryRef.current.scrollIntoView();
  const executeScrollToBuilder = () => {
    builderRef.current.scrollIntoView();
  }

  const addImage = (tokenId) => {
    let current = [...selectedImages];
    let index = current.indexOf("");
    if (index >= 0) {
      current[index] = tokenId;
      setSelectedImages(current);
      setTeamMember(checkTeam(current));
    }
  };

  const removeImage = (tokenId) => {
    let current = [...selectedImages];
    let index = current.indexOf(tokenId);
    if (index >= 0) {
      current[index] = "";
      setSelectedImages(current);
      setTeamMember("");
    }
  };

  const checkTeam = (current) => {
    let index = current.indexOf("");
    if (index < 0) {
      let Num = 0;
      // let rltStr="";
      for (let i = 0; i < current.length; i++) {
        if (tokenData[current[i]].Character.toString() == "Tabbi") {
          Num += 1000;
        } else if (tokenData[current[i]].Character.toString() == "Cap") {
          Num += 100;
        } else if (tokenData[current[i]].Character.toString() == "Rex") {
          Num += 10;
        } else if (tokenData[current[i]].Character.toString() == "Syrin") {
          Num += 1;
        }
        // if(i==0){
        //     rltStr=rltStr+tokenData[current[i]].Character.toString();
        // }else{
        //     rltStr=rltStr+" "+tokenData[current[i]].Character.toString();
        // }
      }
      if (Num == 4000) {
        return "TEAM TABBI";
      } else if (Num == 400) {
        return "CAP CREW";
      } else if (Num == 40) {
        return "REX RIDERS";
      } else if (Num == 4) {
        return "SYRIN SQUAD";
      } else if (Num == 1111) {
        return "DRx SET";
      } else {
        return "Drug Receipts";
      }
    } else {
      return "";
    }
  }

  const getTokens = async (data) => {
    setTokens(data);
    let flag = false;
    for (let i = 0; i < whitelist.length; i++) {
      if (whitelist[i] == wallet.account) {
        flag = true;
      }
    }
    setWhitelistWalletConnected(flag);
    if (flag) {
      setType(5);
      setFrame("white", 5);
    } else {
      let tokenLength = data.length;
      if (tokenLength < 1) {
        setType(0);
        setFrame("white", 0);
      } else if (tokenLength <= 9) {
        let tokenInfo = [];
        let set = [];
        set["Tabbi"] = 0;
        set["Cap"] = 0;
        set["Rex"] = 0;
        set["Syrin"] = 0;
        let setVal = 0;

        for (let i = 0; i < tokenLength; i++) {
          // tokenInfo[i] = await AssetAPI.getTokenInfo(data[i]);
          set[tokenData[data[i]].Character]++;
        }
        if (set["Tabbi"] > 0) setVal++;
        if (set["Cap"] > 0) setVal++;
        if (set["Rex"] > 0) setVal++;
        if (set["Syrin"] > 0) setVal++;
        if (setVal == 4) {
          setType(2);
          setFrame("white", 2);
        } else {
          setType(1);
          setFrame("white", 1);
        }
      } else if (tokenLength <= 49) {
        setType(3);
        setFrame("white", 3);
      } else {
        setType(4);
        setFrame("white", 4);
      }
    }
  };

  const setFrame = (temp, xtype) => {
    let imageUrl = "";
    if (xtype == 1) {
      imageUrl = imageUrl + "SnugDealers_" + temp;
    } else if (xtype == 2) {
      imageUrl = imageUrl + "SetTrippers_" + temp;
    } else if (xtype == 3) {
      imageUrl = imageUrl + "BigPharma_" + temp;
    } else if (xtype == 4) {
      imageUrl = imageUrl + "Kingpin_" + temp;
    } else if (xtype == 5) {
      imageUrl = imageUrl + "Boss_" + temp;
    } else {
      imageUrl = imageUrl + "SnugDealers_" + temp;
    }
    setFrameImage(imageUrl);
  };

  const flipCard = () => {
    setFlip(!flip);
    let temp = teamLore;
    setTeamLore(teamLore + "");
    setTimeout(() => {
      setTeamLore(teamLore);
    }, 100);
  };

  const showGallery = () => {
    setGallery(true);
  }

  const setGender = (event) => {
    let temp = event.target.value ? event.target.value : "white";
    setFrame(temp, type);
  };

  const saveToGallery = async () => {
    let current = [...selectedImages];
    let index = current.indexOf("");
    if (index < 0) {
      let data = {
        walletAddress: wallet.account,
        // walletAddress: "0x7f8Aa85898eeAcEF75c98799e6a308782bbeC7B9",
        teamName: teamName,
        teamMember: teamMember,
        token1: selectedImages[0],
        token2: selectedImages[1],
        token3: selectedImages[2],
        token4: selectedImages[3],
        teamLore: teamLore,
        color: type,
        frame: frameImage
      }
      setIsloading(true);
      let res;
      if (editGalleryNumber >= 0) {
        res = await AssetAPI.updateGallery(data, editGalleryNumber);
      } else {
        res = await AssetAPI.saveToGallery(data);
      }
      if (res.ok) {
        NotificationManager.success('Successfully saved. Snug yeah!');
        setEditGalleryNumber(-1);
        refreshGallery();
        clearAllWithInfo();
      } else {
        NotificationManager.error('Error message', "Cannot save!");
      }
      setIsloading(false);
    } else {
      console.log('Have to select 4 drug receipts.')
    }
  };

  const refreshGallery = async () => {
    if (wallet.account) {
      setIsloading(true);
      let res = await AssetAPI.getGalleriesByAddress(wallet.account);
      // let res = await AssetAPI.getGalleriesByAddress("0x7f8Aa85898eeAcEF75c98799e6a308782bbeC7B9");
      let gallery_data = await res.json();
      if (res.ok) {
        // console.log("$$$$$$$$$$$$$$$$$$$$$$", gallery);
        setGalleries(gallery_data);
      }
      setIsloading(false);
    }
  };

  const downloadGallery = (gallery) => {
    setTeamName(gallery.teamName);
    setSelectedImages([gallery.token1, gallery.token2, gallery.token3, gallery.token4]);
    setEditSelected([gallery.token1, gallery.token2, gallery.token3, gallery.token4]);
    setTeamLore(gallery.teamLore);
    // setFrameImage("");
    setTeamMember(gallery.teamMember);
    setFrameImage(gallery.frame);
    setTimeout(() => {
      downloadImage();
    }, 6000);
    setEditGalleryNumber(-1);
    // console.log("^^^^^^^^^^^^^", gallery);
  }


  const downloadFront = (data) => {
    // let links = [];

    links.push(data);
    // var zip = new JSZip();
    // var count = 0;
    // var zipFilename = "DRx_#_png.zip";
    // links.forEach(function (url, i) {
    //     var filename = "drug_receipts_front.png";//links[i].substring(links[i].lastIndexOf("/") + 1);
    //     JSZipUtils.getBinaryContent(url, function (err, data) {
    //         if (err) {
    //             throw err;
    //         }
    //         zip.file(filename, data, { binary: true });
    //         count++;
    //         if (count === links.length) {
    //             zip.generateAsync({ type: "blob" }).then(function (content) {
    //                 FileSaver.saveAs(content, zipFilename);
    //             });
    //         }
    //     });
    // });
  }

  const downloadBack = (data) => {
    // let links = [];
    // console.log("!!!!!!!!!!!!!!!!!",links);
    links.push(data);
    // console.log("@@@@@@@@@@@@@@@@@@@",links.length);

    let links_temp = links;

    var zip = new JSZip();
    var count = 0;
    var zipFilename = "Drug wars.zip";
    links_temp.forEach(function (url, i) {
      var filename = "";
      if (i == 0) {
        filename = "front.png";
      } else {
        filename = "back.png";
      }
      // var filename = links_temp[i].substring(links_temp[i].lastIndexOf("/") + 1); //"drug_receipts_back.png";//
      JSZipUtils.getBinaryContent(url, function (err, data) {
        if (err) {
          throw err;
        }
        zip.file(filename, data, { binary: true });
        count++;
        if (count === links_temp.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            FileSaver.saveAs(content, zipFilename);
          });
        }
      });
    });
    links = [];
  }

  const editGallery = (gallery) => {
    setTeamName(gallery.teamName);
    setTeamLore(gallery.teamLore);
    // setFrameImage("");
    setTeamMember(gallery.teamMember);
    setFrameImage(gallery.frame);
    setEditGalleryNumber(gallery.id);
    setSelectedImages([gallery.token1, gallery.token2, gallery.token3, gallery.token4]);
    setEditSelected([gallery.token1, gallery.token2, gallery.token3, gallery.token4]);
    // console.log("^^^^^^^^^^^^^", gallery);
  }

  const downloadImage = () => {
    setDownloaded(downloaded + 1);
  };
  return (
    <div>
      <div className="sticky top-0 z-[30]">
        <Header
          menuItems={[
            "Home",
            "Drug inspector",
            "Drug wars",
            "Drug store"
          ]}
          rightArea={null}
          showSocial={true}
          externalLink={false}
          routes={["https://drugreceipts.com/", "https://druginspector.drugreceipts.com/", "/drugwars", "#"]}
        />
      </div>
      <div className={isloading ? 'block' : 'hidden'}>
        <Loader></Loader>
      </div>
      <div className={gallery ? "hidden" : ""}>
        <div className="min-w-screen" >
          <div className="bg-dwsbg min-h-[1000px] md:min-h-[800px] lg:px-0 flex bg-cover xl:bg-[length:100%_auto] 2xl:bg-[length:100%_100%]" style={{ height: 'calc(56vw - 80px)' }}>
            <div className="lg:max-w-[1140px] mx-auto px-5 lg:px-0 pb-[80px]">
              <div className="flex flex-col h-full mx-auto">
                <div className="flex items-center justify-center w-full h-full gap-8 ">
                  <div className="w-full md:pr-[7%] lg:w-1/2 my-10">
                    {/* <img className="w-[440px] my-8 " src={logo} alt="logo" /> */}
                    <p className="font-pressio-condensed text-white text-7xl xl:text-8xl font-semibold">DESTINY</p>
                    <p className="font-pressio-condensed text-white text-7xl xl:text-8xl font-semibold">IS CALLING</p>
                    <p className="font-Montserrat-Regular text-white text-xl xl:text-2xl font-semibold mt-[16px]">This is your moment, Drug Snuggler!</p>
                    <p className="font-Montserrat-Light text-white text-lg xl:text-xl font-light mt-[16px]">
                      Here you will find all that you need in your campaign for victory. Use the Battlecard Builder below to assemble your Drug Receipts NFTs into curated teams of four. Use your skills of craft, cleverness, and cunning to outwit your opponents. Your quest for immortality begins here. Welcome to the Drug Wars!
                    </p>

                    <div className="gap-4 text-[28px] mt-[40px] md:pr-[36%]">
                      <Button clickFunction={executeScrollToBuilder} className="">
                        BEGIN YOUR CAMPAIGN
                      </Button>
                    </div>
                    <p className="font-Montserrat-Light text-white text-lg xl:text-xl  font-light mt-[16px]">Please read the <a href="https://drugreceipts.com/drug-wars-tc/" className="underline font-semibold font-Montserrat-Regular" target="_blank"> Terms & Conditions</a></p>
                    <div className="gap-4 text-[28px] md:pr-[36%]">
                      <img src={flightLogo} className="w-[84%] ml-[8%] mt-[20px]"></img>
                    </div>
                  </div>

                  <div className="w-1/2 pl-[7%] mb-[64px] hidden lg:block">

                  </div>
                  {/* <img
                  // src={drxCharacter}
                  className="w-1/2 pl-[7%] mb-[64px] hidden lg:block"
                  // alt="Drug Wars Character"
                  width={470}
                  height={505}
                /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="min-w-screen min-h-screen bg-[#031b2f] bg-cover bg-fixed pb-8">
          <MainPage>
            <div ref={builderRef} className="flex flex-col w-full mt-[40px] pb-[40px] mx-auto p-4 px-0">
              <div className=" w-full ">
                <div className="text-white my-10">
                  <h1 className="font-pressio-condensed text-5xl lg:text-6xl font-medium mb-2">
                    BATTLECARD BUILDER
                  </h1>
                  <p className="font-Montserrat-Light text-xl font-light">
                    Round up the troops! Assemble your Drug Receipts NFTs into
                    curated groups of four. Choose a frame for your battlecard,
                    give your team a name, and provide some team lore for the back
                    of the card. You can review and edit all of your battlecards in the Gallery below.
                  </p>
                </div>
              </div>

              <div className="flex flex-col w-full mx-auto pb-2 ">
                <div className="w-full h-full sm:w-full sm:px-0">
                  <div className="">
                    <div className="flex flex-col md:flex-row gap-0 md:gap-4">
                      <div className="max-w-[460px]  mt-8 md:mt-0 ">
                        <ReactCardFlip isFlipped={flip}
                          flipDirection="horizontal">
                          <div>
                            <FrameCanvas
                              nfts={selectedImages}
                              myteamName={teamName}
                              frameUrl={frameImage}
                              teamMember={teamMember}
                              downloaded={downloaded}
                              downloadFront={downloadFront}
                            />
                          </div>
                          <div>
                            <FrameBackCanvas
                              nfts={selectedImages}
                              myteamName={teamName}
                              myteamLore={teamLore}
                              frameUrl={frameImage}
                              showGallery={showGallery}
                              downloaded={downloaded}
                              downloadBack={downloadBack}
                            />
                          </div>
                        </ReactCardFlip>
                        <div className="mt-8">
                          <Button clickFunction={downloadImage}>
                            <a
                              className="text-[28px]"
                              target="_blank"
                              rel="noreferrer"
                            >
                              DOWNLOAD PNG
                            </a>
                          </Button>
                        </div>
                        <div className="block md:grid grid-cols-2 gap-4">
                          <div className="col-span-1">
                            <ButtonWar clickFunction={saveToGallery}>
                              <a className="text-[28px]" target="_blank" rel="noreferrer">
                                {editGalleryNumber >= 0 ? 'Save Changes' : 'SAVE TO GALLERY'}
                              </a>
                            </ButtonWar>
                          </div>
                          <div className="col-span-1">
                            <ButtonWar clickFunction={executeScrollToGallery}>
                              <a className="text-[28px]" target="_blank" rel="noreferrer">
                                View GALLERY
                              </a>
                            </ButtonWar>
                          </div>
                        </div>
                      </div>
                      <div
                        className="w-full lg:w-[660px] order-first md:order-last
                                      overflow-x-auto md:overflow-x-hidden"
                      >
                        <ReactCardFlip isFlipped={flip}
                          flipDirection="vertical">
                          <div className="rounded-[15px]">
                            <div className="h-[360px] md:h-[430px] bg-white p-3 pr-[4px] rounded-[15px]">
                              <CustomScrollbars>
                                <div className="pr-[13px]">
                                  <NFTs
                                    // walletAddress={
                                    //   "0x7f8Aa85898eeAcEF75c98799e6a308782bbeC7B9" /*TODO: wallet.account*/
                                    // }
                                    // walletAddress={
                                    //     "0xa766eddd4a4d43eba40ea1ea4cbdb9835077df44" /*TODO: wallet.account*/
                                    // }
                                    walletAddress={wallet.account}
                                    className=""
                                    onSelect={addImage}
                                    getTokens={getTokens}
                                    onDeselect={removeImage}
                                    displayAll={true}
                                    clear={clear}
                                    editSelected={editSelected}
                                  ></NFTs>
                                </div>
                              </CustomScrollbars>
                            </div>
                          </div>
                          <div className="rounded-[15px]">
                            <div className="h-[340px] md:h-[420px] bg-white p-4 relative rounded-[15px] font-Montserrat-Regular">
                              <span className="absolute right-5 text-[#999999] font-open-sans"><span className="font-bold">{teamLoreLength}</span>/750</span>
                              <p className="text-xl text-[#818386] font-bold ml-1 font-Montserrat-Regular">
                                TEAM LORE
                              </p>
                              <textarea
                                class="rounded-[8px] p-2 pl-3 w-full h-[190px] md:h-[300px] rounded-[16px] border-2 border-black mt-3 font-Montserrat-Light"
                                placeholder="Enter your team lore here"
                                value={teamLore}
                                onChange={({ target: { value } }) => {
                                  const lines = value.toString().split(/\r\n|\r|\n/);
                                  // console.log("$$$", lines);
                                  let temp_length = 0;
                                  for (let i = 0; i < lines.length; i++) {
                                    if (i == lines.length - 1) {
                                      temp_length = temp_length + lines[i].length;
                                    } else {
                                      if (lines[i].length > 53) {
                                        temp_length += lines[i].length;
                                      } else {
                                        temp_length = temp_length + 53 - lines[i].length;
                                      }
                                    }
                                  }
                                  if (temp_length <= 750) {
                                    setTeamLoreLength(temp_length);
                                    setTeamLore(value);
                                  }
                                }}
                              ></textarea>
                              <p className="mt-1 font-Montserrat-Light leading-4 text-sm md:text-md font-light text-[#818386]">
                                Does your team have an origin story? A shared
                                objective? A favorite pizza topping? Use the team lore
                                to provide some background. This will appear on the
                                back of your battlecard.
                              </p>
                            </div>
                          </div>
                        </ReactCardFlip>
                        <div className="col-span-1 md:pr-[7%] relative">
                          <div className="absolute right-0 flex space-x-2">
                            <div
                              className={`w-[145px] h-[39.8px] mt-4 bg-cover ${flip ? "bg-cadbn" : "bg-cabn hover:bg-cahbn"}`}
                              style={{ backgroundSize: '100% 100%' }}
                              onClick={() => clearAll()}
                            ></div>
                            <div
                              className="w-[150px] h-[39.8px] mt-4 bg-fldbn bg-cover hover:bg-flhbn"
                              style={{ backgroundSize: '100% 100%' }}
                              onClick={() => flipCard()}
                            ></div>
                          </div>
                          <br></br>
                          <div className="mt-12 md:mt-[2.3rem]">
                            <div className="pl-[4px]">
                              <p className="text-xl text-white font-bold font-Montserrat-Regular">
                                CHOOSE YOUR FRAME
                              </p>
                              <div
                                className="flex flex-row md:flex-col gap-2 md:gap-0 md:space-y-3 mt-2 font-Montserrat-Light"
                                onChange={setGender.bind(this)}
                              >
                                <div className="block md:flex flex-row space-y-2 md:space-y-0">
                                  <div className="flex items-center md:w-[120px]">
                                    <input
                                      type="radio"
                                      value="white"
                                      name="type"
                                      id="radio1"
                                      className="h-[24px] w-[24px]"
                                      defaultChecked
                                    />
                                    <label
                                      for="radio1"
                                      className="flex items-center cursor-pointer ml-3 -mt-[0.5px] text-md md:text-xl text-white"
                                    >
                                      White
                                    </label>
                                  </div>
                                  <div className="flex items-center md:w-[240px] md:ml-4">
                                    <input
                                      type="radio"
                                      value="blue"
                                      name="type"
                                      id="radio3"
                                      className="h-[24px] w-[24px]"
                                      disabled={!whitelistWalletConnected && type < 2}
                                    />
                                    <label
                                      for="radio3"
                                      className="flex items-center cursor-pointer ml-3 -mt-[0.5px] text-md md:text-xl text-[#4EA0C9]"
                                    >
                                      Set Tripper Blue
                                    </label>
                                  </div>
                                  <div className="flex items-center md:w-[240px] md:ml-4">
                                    <input
                                      type="radio"
                                      value="gold"
                                      name="type"
                                      id="radio5"
                                      className="h-[24px] w-[24px]"
                                      disabled={!whitelistWalletConnected && type < 4}
                                    />
                                    <label
                                      for="radio5"
                                      className="flex items-center cursor-pointer ml-3 -mt-[0.5px]  text-md md:text-xl text-[#FFA61F]"
                                    >
                                      Kingpin Gold
                                    </label>
                                  </div>
                                </div>
                                <div className="block md:flex flex-row space-y-2 md:space-y-0 font-Montserrat-Light">
                                  <div className="flex items-center md:w-[120px]">
                                    <input
                                      type="radio"
                                      value="gray"
                                      name="type"
                                      id="radio2"
                                      className="h-[24px] w-[24px]"
                                    />
                                    <label
                                      for="radio2"
                                      className="flex items-center cursor-pointer ml-3 -mt-[0.5px]  text-md md:text-xl text-[#AAAAAA]"
                                    >
                                      Gray
                                    </label>
                                  </div>
                                  <div className="flex items-center md:w-[240px] md:ml-4">
                                    <input
                                      type="radio"
                                      value="green"
                                      name="type"
                                      id="radio4"
                                      className="h-[24px] w-[24px]"
                                      disabled={!whitelistWalletConnected && type < 3}
                                    />
                                    <label
                                      for="radio4"
                                      className="flex items-center cursor-pointer ml-3 -mt-[0.5px] text-md md:text-xl text-[#81B82C]"
                                    >
                                      Big Pharma Green
                                    </label>
                                  </div>
                                  <div className="flex items-center md:w-[240px] md:ml-4">
                                    <input
                                      type="radio"
                                      value="purple"
                                      name="type"
                                      id="radio6"
                                      className="h-[24px] w-[24px]"
                                      disabled={!whitelistWalletConnected}
                                    />
                                    <label
                                      for="radio6"
                                      className="flex items-center cursor-pointer ml-3 -mt-[0.5px]  text-md md:text-xl text-[#ad7dab]"
                                    >
                                      {whitelistWalletConnected ? "Boss purple" : "Undisclosed"}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <br></br>
                            <img src={stampImg} className="absolute w-[100px] -mt-[24px] right-0 hidden lg:block"></img>
                            <div className="relative w-[100%] lg:w-[75%] mt-[10px] pl-[4px]">
                              <p className="text-xl text-white font-bold font-Montserrat-Regular">
                                TEAM NAME
                              </p>
                              <span className="absolute right-4 mt-4 text-[#999999] font-open-sans"><span className="font-bold">{teamName.length}</span>/26</span>
                              <input
                                class="rounded-[8px] p-2 pl-3 w-full rounded-[8px] mt-2 font-open-sans"
                                placeholder="Type team name here"
                                value={teamName}
                                onChange={({ target: { value } }) => {
                                  if (value.length <= 26) {
                                    setTeamName(value);
                                  }
                                }}
                              ></input>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </MainPage>
        </div>
      </div>
      <div>
        <div ref={galleryRef} className="min-w-screen min-h-screen bg-dgbg bg-cover bg-fixed">
          <Gallery walletAddress={wallet.account} galleries={galleries} refreshGallery={refreshGallery} executeScrollToBuilder={() => { executeScrollToBuilder() }} editGallery={editGallery} downloadGallery={downloadGallery} clearAllWithInfo={clearAllWithInfo} />
        </div>
      </div>
      <NotificationContainer />
    </div>
  );
}
